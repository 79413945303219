exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-fr-js": () => import("./../../../src/pages/about.fr.js" /* webpackChunkName: "component---src-pages-about-fr-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-construction-fr-js": () => import("./../../../src/pages/construction.fr.js" /* webpackChunkName: "component---src-pages-construction-fr-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-contact-a-map-js": () => import("./../../../src/pages/contact/AMap.js" /* webpackChunkName: "component---src-pages-contact-a-map-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact/Form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-fr-js": () => import("./../../../src/pages/contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-links-js": () => import("./../../../src/pages/contact/Links.js" /* webpackChunkName: "component---src-pages-contact-links-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-homepage-about-js": () => import("./../../../src/pages/homepage/About.js" /* webpackChunkName: "component---src-pages-homepage-about-js" */),
  "component---src-pages-homepage-components-appetizers-js": () => import("./../../../src/pages/homepage/components/Appetizers.js" /* webpackChunkName: "component---src-pages-homepage-components-appetizers-js" */),
  "component---src-pages-homepage-components-hosomaki-js": () => import("./../../../src/pages/homepage/components/Hosomaki.js" /* webpackChunkName: "component---src-pages-homepage-components-hosomaki-js" */),
  "component---src-pages-homepage-components-maki-2-js": () => import("./../../../src/pages/homepage/components/Maki2.js" /* webpackChunkName: "component---src-pages-homepage-components-maki-2-js" */),
  "component---src-pages-homepage-components-maki-js": () => import("./../../../src/pages/homepage/components/Maki.js" /* webpackChunkName: "component---src-pages-homepage-components-maki-js" */),
  "component---src-pages-homepage-components-menu-1-js": () => import("./../../../src/pages/homepage/components/Menu1.js" /* webpackChunkName: "component---src-pages-homepage-components-menu-1-js" */),
  "component---src-pages-homepage-components-menu-2-js": () => import("./../../../src/pages/homepage/components/Menu2.js" /* webpackChunkName: "component---src-pages-homepage-components-menu-2-js" */),
  "component---src-pages-homepage-components-menu-3-js": () => import("./../../../src/pages/homepage/components/Menu3.js" /* webpackChunkName: "component---src-pages-homepage-components-menu-3-js" */),
  "component---src-pages-homepage-components-nigiri-js": () => import("./../../../src/pages/homepage/components/Nigiri.js" /* webpackChunkName: "component---src-pages-homepage-components-nigiri-js" */),
  "component---src-pages-homepage-components-salads-js": () => import("./../../../src/pages/homepage/components/Salads.js" /* webpackChunkName: "component---src-pages-homepage-components-salads-js" */),
  "component---src-pages-homepage-components-table-js": () => import("./../../../src/pages/homepage/components/Table.js" /* webpackChunkName: "component---src-pages-homepage-components-table-js" */),
  "component---src-pages-homepage-components-tabs-js": () => import("./../../../src/pages/homepage/components/Tabs.js" /* webpackChunkName: "component---src-pages-homepage-components-tabs-js" */),
  "component---src-pages-homepage-components-tartares-js": () => import("./../../../src/pages/homepage/components/Tartares.js" /* webpackChunkName: "component---src-pages-homepage-components-tartares-js" */),
  "component---src-pages-homepage-french-about-fr-js": () => import("./../../../src/pages/homepage/french/About.fr.js" /* webpackChunkName: "component---src-pages-homepage-french-about-fr-js" */),
  "component---src-pages-homepage-french-instagram-fr-js": () => import("./../../../src/pages/homepage/french/Instagram.fr.js" /* webpackChunkName: "component---src-pages-homepage-french-instagram-fr-js" */),
  "component---src-pages-homepage-french-menu-fr-js": () => import("./../../../src/pages/homepage/french/Menu.fr.js" /* webpackChunkName: "component---src-pages-homepage-french-menu-fr-js" */),
  "component---src-pages-homepage-french-news-fr-js": () => import("./../../../src/pages/homepage/french/News.fr.js" /* webpackChunkName: "component---src-pages-homepage-french-news-fr-js" */),
  "component---src-pages-homepage-french-oceanwise-fr-js": () => import("./../../../src/pages/homepage/french/Oceanwise.fr.js" /* webpackChunkName: "component---src-pages-homepage-french-oceanwise-fr-js" */),
  "component---src-pages-homepage-french-press-fr-js": () => import("./../../../src/pages/homepage/french/Press.fr.js" /* webpackChunkName: "component---src-pages-homepage-french-press-fr-js" */),
  "component---src-pages-homepage-header-js": () => import("./../../../src/pages/homepage/Header.js" /* webpackChunkName: "component---src-pages-homepage-header-js" */),
  "component---src-pages-homepage-image-break-js": () => import("./../../../src/pages/homepage/ImageBreak.js" /* webpackChunkName: "component---src-pages-homepage-image-break-js" */),
  "component---src-pages-homepage-instagram-js": () => import("./../../../src/pages/homepage/Instagram.js" /* webpackChunkName: "component---src-pages-homepage-instagram-js" */),
  "component---src-pages-homepage-menu-js": () => import("./../../../src/pages/homepage/Menu.js" /* webpackChunkName: "component---src-pages-homepage-menu-js" */),
  "component---src-pages-homepage-news-js": () => import("./../../../src/pages/homepage/News.js" /* webpackChunkName: "component---src-pages-homepage-news-js" */),
  "component---src-pages-homepage-oceanwise-js": () => import("./../../../src/pages/homepage/Oceanwise.js" /* webpackChunkName: "component---src-pages-homepage-oceanwise-js" */),
  "component---src-pages-homepage-press-js": () => import("./../../../src/pages/homepage/Press.js" /* webpackChunkName: "component---src-pages-homepage-press-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

